.mb-30 {
  margin-bottom: 30px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-300 {
  padding-bottom: 300px;
}

.pt-100 {
  padding-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-neg-50 {
  margin-top: -50px;
}

.mt-lg-neg-50 {
  @media screen and (min-width: 992px) {
    margin-top: -50px !important;
  }
}

.mt-xl-neg-100 {
  @media screen and (min-width: 1192px) {
    margin-top: -100px !important;
  }
}

.mt-xl-neg-150 {
  @media screen and (min-width: 1192px) {
    margin-top: -150px !important;
  }
}

.pr-super-xl-100 {
  @media screen and (min-width: 1350px) {
    padding-right: 100px;
  }
}

.mb-neg-12 {
  margin-bottom: -12px;
}

.mb-md-neg-23 {
  @media screen and (min-width: 768px) {
    margin-bottom: -23px;
  }
}

.mb-lg-neg-31 {
  @media screen and (min-width: 992px) {
    margin-bottom: -31px;
  }
}

.top-100 {
  top: 100% !important;
}
