.potted-plant-svg {
  max-width: 200px;
  position: absolute;
  bottom: 0;
  left: 20px;

  @media screen and (max-width: 1191px) {
    display: none;
  }
}

.min-width-300 {
  min-width: 300px;
}

.xl-110 {
  @media screen and (min-width: 1192px) {
    width: 110%;
  }
}

.w-100-md{
  @media screen and (max-width: 992px){
    width: 100%;
  }
}
.w-100-sm{
  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.opacity-md-0{
  opacity: 1;
  @media screen and ( min-width: 992px){
    opacity: 0;
  }
}

.overflow-md-hidden{
  @media screen and (min-width: 992px) and (max-width: 1192px){
    overflow:hidden;
  }
}

.img-xl-drift-left {
  @media screen and (min-width: 1192px) {
    width: 124%;
    margin-left: -15%;
    z-index: -1;
  }
}

.subtle-link {
  color: #000;
  text-decoration: none;

  &:hover {
    color: lighten(#000, 30%);
    text-decoration: none;
  }
}

#available-jobs .blankcontent div {
  color: $primary !important;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Open Sans';
}

#available-jobs {
  font-family: 'Open Sans';
  font-size: 18px;
  color: #423f3f;
  line-height: 32px;
}

.approach-row{
  &:first-of-type{
    margin-top: 54px;
    margin-bottom: 54px;
  }
  margin-bottom: 54px;
}

.employers-connect-graphic{
    margin-top: -80px;
  @media screen and (min-width: 576px){
    margin-top: -100px;
  }
  @media screen and (min-width: 768px){
    margin-top: -150px;
  }
  @media screen and (min-width: 992px){
    margin-top: -225px;
  }
}
.employers-connect{
  padding-top: 45px;
  padding-bottom: 45px;
  @media screen and (min-width: 992px){
    padding-top: 90px;
    padding-bottom: 90px;
  }
  background-color: #aec4e4;
  background-color: #F8F7F5, rgba(#aec4e4, 0.75);
  p{
    @media screen and (min-width: 992px){
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.schedule-container{
  @media screen and (max-width: 992px){
    margin-top: 27px;
  }
  @media screen and (min-width: 992px){
    margin-top: 72px;
  }
  img{
      @media screen and (max-width: 768px){
        margin-top: 3rem;
      }
      margin-bottom: -2px;
      width: 100%;
    }
}

.mt-64{
  @media screen and (min-width: 992px){
    margin-top: 64px;
  }
}

.opacity-md-0{
  @media screen and (min-width: 992px){
    opacity: 0;
  }
}

.growing-blob-bg{
  @media screen and (min-width: 992px){
    background: #ffffff url(/assets/svgs/human_resources/fill.svg) 7% 0%/ 50% auto no-repeat;
  }
  @media screen and (min-width: 1920px){
    background: #ffffff url(/assets/svgs/human_resources/fill.svg) 7% 0%/ 45% auto no-repeat;
  }
}
.employers-section-3{
  @media screen and (min-width: 992px){
    background: url(/assets/svgs/employers/looking-for-talent-match-making.svg) 100% 60%/60% auto no-repeat;
    background: url(/assets/svgs/employers/looking-for-talent-match-making.svg) 105% 100%/65% auto no-repeat;
  }
  @media screen and (min-width: 1200px){
    background: url(/assets/svgs/employers/looking-for-talent-match-making.svg) 105% 100%/45% auto no-repeat;
  }
  @media screen and (min-width: 1600px){
    background: url(/assets/svgs/employers/looking-for-talent-match-making.svg) 105% 100%/45% auto no-repeat;
  }
  @media screen and (min-width: 1920px){
    background: url(/assets/svgs/employers/looking-for-talent-match-making.svg) 105% 100%/45% auto no-repeat;
  }
}

.employer-hero{
  margin-top: 10px;
  @media screen and (min-width: 992px){
    margin-top: 62px;
    background: #ffffff url(/assets/svgs/employers/fill.svg) 100% 100%/75% auto no-repeat;
    img{
      max-width: 95%;
    }
  }
  @media screen and (min-width: 1200px){
    background: #ffffff url(/assets/svgs/employers/fill.svg) 100% 100%/62% auto no-repeat;
  }
  @media screen and (min-width: 1600px){
    background: #ffffff url(/assets/svgs/employers/fill.svg) 100% 100%/57% auto no-repeat;
  }
  @media screen and (min-width: 1920px){
    background: #ffffff url(/assets/svgs/employers/fill.svg) 100% 100%/55% auto no-repeat;
  }
}

.sp-approach{
  background: url(/assets/svgs/employers/our-approach-bg.svg) top center/220% auto no-repeat;
  @media screen and (min-width: 992px){
    padding-top: 70px;
    background: url(/assets/svgs/employers/our-approach-bg.svg) top center/100% auto no-repeat;
  }
  h1{
    margin-bottom: 20px;
    @media screen and (min-width: 992px){
      margin-bottom: 30px;
    }
  }
  .offset-copy{
    @media screen and (min-width: 992px){
      max-width: 60%;
      flex-basis: 60%;
    }
  }
}
.fs-30{
  @media screen and (max-width: 768px){
    font-size: 1.875rem;
  }
}
.fs-32{
  @media screen and (max-width: 768px){
    font-size: 2rem;
  }
}

.text-align-left-small{
  @media screen and (max-width: 576px){
    text-align: left !important;
  }
}
