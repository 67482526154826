.cookieAlert {
  padding: 15px;
  background: lighten($aqua, 5%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position:fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;

  p, a {
    color: #fff;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  .exit::after {
    content: 'X';
    background: #FFF;
    color: lighten($aqua, 5%);
    opacity: 0.8;
    padding: 5px 10px;
    border-radius: 17px;
    font-size: 12px;
    font-weight: bold;
  }

  .exit:hover {
    cursor: pointer;

    &::after {
      opacity: 1;
    }
  }
}
