.blog-card {
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
  border-radius: 3px;
  text-align: center;
  background: #FFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin-top: 30px;
    max-height: 300px;
  }
}

.paginatedBlogPost a, .homepage-blog-posts a {
  text-decoration: none;

  &:hover {
    h3 {
      color: #000;
    }
  }

  p {
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #000;
  }

  .faux-link {
    color: $aqua;
    text-decoration: underline;
  }
}

#blog-post-layout, #blog-layout {
  h2 {
    font-size: 2rem;
  }
  p{
    > img{
      width: 100%;
    }
  }
  blockquote {
    border-left: 10px solid $primary;
    background: $light-grey;
    padding: 35px 35px 15px 35px;

    p {
      font-style: italic;
    }

    @media screen and (min-width: 992px) {
      margin-left: 70px;
      margin-right: 70px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .blog-sharing-links{
    a:nth-child(even){
      margin: 0 8px;
    }
  }
}
