.icon {
  display: inline-block;
}

.icon-address {
  background: url("../../assets/svgs/address.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 20px;
  width: 15px;
}

.icon-phone {
  background: url("../../assets/svgs/phone.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 15px;
  width: 20px;
}

.icon-email {
  background: url("../../assets/svgs/email.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 11px;
  width: 18px;
}

.icon-facebook {
  background: url("../../assets/svgs/facebook.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 41px;
  width: 36px;
}

.icon-linkedin {
  background: url("../../assets/svgs/linkedin.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 41px;
  width: 36px;
}

.icon-email {
  background: url("../../assets/svgs/email-share.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 17px;
  width: 15px;
}

.icon-right:after {
  content: "\203A";
  font-size: 25px;
  line-height: 10px;
  padding-left: 10px;
}

.icon-left:after {
  content: "\2039";
  font-size: 25px;
  line-height: 10px;
  padding-right: 10px;
}
