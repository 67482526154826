.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #2b75c5;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: 700;
  color: #000;
  text-decoration: none;
}

.navbar-nav{
  flex-wrap: wrap;
  justify-content: flex-end;
}

.navbar-brand {
  width: 350px;
  max-width: 60%;
}

.navbar {
  @media screen and (max-width: 991px) {
    border-bottom: 1px solid #333;
  }
}
@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $primary;
    z-index: 200;
    padding: 70px 40px 20px;
    transition: all 0.4s ease;
    display: block;
  }
  .navbar-toggler {
    z-index: 300;
    transition: all 0.4s ease;
  }
  .navbar-collapse.collapsing {
    height: auto;

    margin-left: 50%;
    left: 50%;
    -webkit-transition: left 0.2s ease;
    -o-transition: left 0.2s ease;
    -moz-transition: left 0.2s ease;
    transition: left 0.2s ease;
  }
  .navbar-collapse.show {
    left: 0;
  }
  .nav-link {
    color: #FFF !important;
    font-size: 26px;
    &:hover, &:focus {
      color: #3a85d4 !important;
    }
  }

  .nav-item.active {
    .nav-link {
      color: #3a85d4 !important;
    }
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter, color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  color: #000;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #FFF;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
