.minimal-input {
  background: transparent;
  border: 0px;
  border-bottom: 2px solid #777;
  border-radius: 0px;
}

.successful-form-submital-message {
  color: $primary;
  font-size: 16px;
}
