.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container {
  position: absolute;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $primary;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
}
@keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
}

.display-none.on{
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
