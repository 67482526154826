.simple-pagination {
  li.disabled {
    display: none !important;
  }

  .active {
    color: #FFF !important;
    background: lighten($aqua, 10%) !important;
    border: 0px;
    padding: 8px 15px;
    border-radius: 20px;

    &:hover {
      background: lighten($aqua, 10%) !important;
    }
  }

  a {
    margin-right: 0px !important;
  }

  .current {
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
  }

  .page-link {
    padding: 8px 14px;
    border-radius: 20px;
    background: transparent;
    text-decoration: none;
    border: 0px;
    box-shadow: none;
    transition: 0.2s;
  }

  .next, .prev {
    line-height: 10px;
    font-size: 30px;
    margin-top: -3px;
  }

  a:hover {
    background: transparent !important;
    color: #fff !important;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin: 0px 7px !important;

    &:hover {
      color: #FFF !important;
      background: $primary !important;

      a {
        color: #fff !important;
      }
    }
  }

  .next-li, .prev-li {
    border: 2px solid $primary !important;

    &:hover {
      color: #fff !important;
      background: $primary !important;
    }

    a {
      color: $primary;
    }
  }
}
