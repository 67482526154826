@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/OpenSans-Regular.ttf") format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/OpenSans-Bold.ttf") format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/OpenSans-ExtraBold.ttf") format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/Open_Sans/OpenSans-Italic.ttf") format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6, p, a, li {
  font-family: 'Open Sans';
}

h1 {
  font-size: 3rem;

  @media screen and (max-width: 767px) {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 18px;
  color: #423f3f;
  line-height: 32px;
}

a {
  color: $aqua;
  text-decoration: underline;

  &:hover {
    color: darken($aqua, 10%);
  }
}

.lh-25 {
  line-height: 2.5rem;
}

.default-color-size {
  font-size: 16px;
  color: #000;
}

.default-size {
  font-size: 16px;
}

.no-break {
  white-space: nowrap;
}

.aqua-link {
  color: $aqua !important;
  text-decoration: underline;

  &:hover {
    color: darken($aqua, 10%) !important;
  }
}

.white-link {
  color: #FFF !important;
  text-decoration: none;

  &:hover {
    color: darken(#FFF, 10%) !important;
  }
}

#privacy-policy li {
  padding-bottom: 10px;
}
