.blob {
  background: #F8F7F5;
}

.blob-container-2 {
  overflow-x: hidden;
  overflow-y: hidden;
}

.blob-container-3 {
  overflow-x: hidden;
  overflow-y: hidden;
}

.blob-1-a {
  height: 300px;
  width: 100%;
  border-radius: 31% 69% 17% 83% / 59% 83% 17% 41%;

  position: absolute;
  right: -100px;
  top: -101px;
  transform: rotateZ(5deg);
}

.blob-1-b {
  height: 200px;
  width: 60%;
  transform: rotateZ(4deg);
  border-radius: 31% 69% 51% 49% / 12% 68% 32% 88%;

  position: absolute;
  right: -184px;
  top: 113px;
}

.blob-2 {
  height: 70%;
  width: 100%;
  border-radius: 45% 55% 35% 65% / 40% 56% 44% 60%;
  position: absolute;
  // vertically center the blob
  top: 35%;
  transform: translateY(-35%);

  @media screen and (max-width: 767px) {
    width: 250%;
    margin-left: -20%;
  }
}

.blob-3 {
  height: 150%;
  width: 70%;
  border-radius: 78% 22% 35% 65% / 67% 30% 70% 33%;
  position: absolute;
  right: -6%;
  bottom: -50%;

  @media screen and (max-width: 768px) {
    display: none;
  }

  &.thin-blob-3 {
    height: 140%;
    width: 60%;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &.reflected-blob {
    left: -6%;
  }
}

.blob-4 {
  height: 100%;
  width: 70%;
  border-radius: 78% 22% 35% 65% / 67% 30% 70% 33%;
  position: absolute;
  right: -6%;
  top: 20%;

  @media screen and (max-width: 768px) {
    display: none;
  }

  &.reflected-blob {
    left: -6%;
  }
}

.reflected-blob {
  transform: rotateY(-180deg);
}

.blob-1-row {
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.blob-1-svg-background {
  background: url('../svgs/background-blob-1.svg');
  width: 208%;
  height: 146%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -136px;
  left: -50%;
  z-index: -1;

  @media screen and (min-width: 768px) {
    width: 150%;
  }

  @media screen and (min-width: 992px) {
    width: 171%;
    left: -28%;
    height: 200%;
    top: -42%;
  }

  @media screen and (min-width: 1192px) {
    width: 300%;
    left: -100%;
    height: 200%;
    top: -42%;
  }
}

.full-width-svg-home-blob {
  z-index: -99999;
  position: absolute;
  right: 0;
  width: 100%;
}
