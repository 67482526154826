.hero-container {
  padding: 40px 0px 0px;
  img{
    &.hr-hero-img{
      width: 100%;
      @media screen and (min-width: 992px){
        width: 115%;
        position: relative;
        left: -6.5%
      }
    }
  }
}
