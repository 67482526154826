.btn {
  text-decoration: none !important;
}

.btn-primary {
  background: $primary;
  border-color: $primary;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;

  &:hover {
    background: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }
}

.btn-primary-outline {
  background: transparent;
  border-color: $primary;
  color: $primary;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  font-weight: 700;
  border-width: 2px;

  &:hover {
    background: $primary;
    border-color: $primary;
    color: #fff;
    font-weight: 700;
  }
}

.btn-outline-white {
  background: transparent;
  border-color: #fff;
  color: #fff;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  font-weight: 700;
  border-width: 2px;

  &:hover {
    background: #fff;
    border-color: #fff;
    color: $primary;
    font-weight: 700;
  }
}

.btn-wide {
  padding: 0.5rem 3rem;
}

.btn-mobile-100 {
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0.9rem 1.5rem;
  }
}
