.col-120-off-left {
  flex: 0 0 120%;
  max-width: 120%;
  margin-left: -20%;
}

.col-120-off-right {
  flex: 0 0 120%;
  max-width: 120%;
  margin-right: -20%;
}

.col-md-60-off-left {
  @media screen and (min-width: 768px) {
    max-width: 60%;
    flex: 0 0 60%;
    margin-left: -10%;
  }
}

.col-md-60-off-right {
  @media screen and (min-width: 768px) {
    max-width: 60%;
    flex: 0 0 60%;
    margin-right: -10%;
  }
}
