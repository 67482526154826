#footer {
  background: #0f2d51;
  color: #fff;

  .container {
    padding: 80px 15px 20px;
  }

  ul {
    list-style-type: none;
    display: inline-block;

    li {
      padding-bottom: 10px;
    }

    li.title {
      font-weight: bold;
    }

    li a {
      color: #fff;
      text-decoration: none;
      font-weight: 200;

      &:hover {
        color: #ccc;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    ul {
      width: 25%;
      padding-left: 0px;
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      padding: 50px 15px;
    }

    ul {
      width: 50%;
    }
  }

  .sub-footer {
    padding: 2rem 15px;
    border-top: 2px solid #364a63;

    p {
      color: #fff;
      font-size: 16px;
      font-weight: 100;
      line-height: 26px;
      margin-bottom: 0px;
    }
  }
}
